<template>
  <div class="case-studies">
    <Suspense>
      <template #default>
        <CaseStudiesDisplay />
      </template>
      <template #fallback>
        ... Loading
      </template>
    </Suspense>
  </div>
</template>

<script>
// @ is an alias to /src
import CaseStudiesDisplay from '@/components/Case-studies-display.vue'

export default {
  name: 'CaseStudies',
  components: {
    CaseStudiesDisplay,
  }
}
</script>
