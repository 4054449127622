<template>
  <div class="case-studies">
    <div class="case-study"
      v-for="(caseStudy, index) in caseStudies"
      :key="`case-study-${index}`"
    >
      <div class="output">
        <h3>{{ caseStudy.output.title }}</h3>
        <div class="controls">
          <div class="status"
            :class="getStatusClass(caseStudy.output.operational_status)"
          >{{ caseStudy.output.operational_status }}</div>
          <a :href="getEditLink(caseStudy.id)" target="_blank" class="direct-edit">
            <img class="edit" src="../assets/create-24px.svg" alt="cancel detail view">
          </a>
        </div>
        <div class="description"
          v-html="caseStudy.output.description"
        />
        <div class="partners">
          <h4>Projektpartner</h4>
          <div><h5>primär:</h5>
            {{ caseStudy.output.primary_partners }} </div>
          <div v-if="caseStudy.output.secondary_partners"><h5>sekundär:</h5>
            {{ caseStudy.output.secondary_partners }} </div>

        </div>
      </div>
      <div class="meta sections">
        <div class="section"
          v-for="(section, ind) in getSections(caseStudy.meta.sections)"
          :key="`section-${ind}`"
          :style="`border-color: ${section.fieldOfActionColor}`"
        >
          <div v-html="section.output.title" />
          <div class="section-id">{{ section.id }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useDnsApiData } from '../use/dns-api-data'
// import { useExtractCategories } from '../use/utils'

export default {
  name: 'CaseStudiesDisplay',
  components: {

  },
  props: {
    
  },
  async setup() {
      const store = useStore()
      const dnsApiData = await useDnsApiData()
      // page config
      const pageTitel = 'Übersicht Case Studies'

      function getEditLink(id) {
        return `https://cms.bua-dns.de/bolt/editcontent/case-studies/${id}`
      }
    
      let { fields_of_action, sections, caseStudies } = dnsApiData
      function getStatusClass(value) {
        if(value === 'in Arbeit') return 'operational'
        if(value === 'in Planung') return 'planed'
        if(value === 'Vorgespräche') return 'considered'
      }
      // Aggregation Sections-Daten

      sections = [...sections].map(section => {
        const fieldOfAction = fields_of_action.find(foa => foa.id === section.meta.field_of_action)
        section.fieldOfActionColor = fieldOfAction.meta.color
        section.fieldOfActionPosition = fieldOfAction.meta.position
        return section

      })
      function getSections(ids) {
        let selection = []
        for (let id of ids) {
          const section = sections.find(s => s.id === id)
          selection.push(section)
        }
        selection = [...selection].sort((a,b) => {
          if(a.fieldOfActionPosition > b.fieldOfActionPosition) return 1
          if(a.fieldOfActionPosition < b.fieldOfActionPosition) return -1
          else return 0

        })
        return selection
      }
      store.dispatch('setHeaderTitle', pageTitel)

      return {
        sections,
        caseStudies,
        getSections,
        getStatusClass,
        getEditLink
      }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.clickable {
  cursor: pointer;
}
.case-studies {
  padding: 0 1rem;
  .case-study {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    padding: 1rem 0;
    border-bottom: 3px solid #ccc;
    .controls {
      margin-top: .4rem;
      display: flex;
      align-content: center;
      .status {
        padding: .25rem .45rem;
        font-size: .75rem;
        font-weight: bold;
        background-color: #aaa;
        color: white;
        border-radius: 2px;
      }
      .operational {
        background-color: green;
      }
      .planed {
        background-color: darkBlue;
      }
      .considered {
        background-color: cornflowerBlue;
      }
      img {
        display: block;
        padding: .2rem;
        width: 1.4rem;
        border: 1px solid #ccc;
        border-radius: 2px;
        cursor: pointer;
        &.edit {
          margin-left: .4rem;        
        }
     }
    }
    .description {
      margin: .5rem 1rem .5rem 0;
      padding: .5rem;
      border: 1px solid #ddd;
    }

    .partners {
      h4 {
        font-size: .9rem;
        font-weight: normal;
        text-transform: uppercase;
        margin: 1rem 0 0;
      }
      h5 {
        font-size: 1;
        font-weight: normal;
        opacity: .75;
        margin: .5rem 0 0;
      }

    }
    .sections {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: .4rem;
      align-content: start;
      .section {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 4rem;
        padding: .25rem;
        font-size: .8rem;
        font-weight: bold;
        word-wrap: break-word;
        border-width: 2px;
        border-style: solid;
        border-radius: 3px;
        transition: font-size 5s ease-in-out;
        &.inactive {
            border-width: 1px;    
        }
        &.active :hover {
            font-size: 103%;
        }
        .section-id {
            position: absolute;
            top: .25rem;
            right: .25rem;
            opacity: .3;
            font-size: .6rem;
          }
        .direct-edit {
          position: absolute;
          top: .25rem;
          left: .25rem;
          opacity: .3;
          img {
            width: .75rem;
          }
        }
      }
    }
  }
}
</style>
